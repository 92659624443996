<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="资产管理" />
		<div class="header">
			<div>账户余额：<span>{{money}}</span>&nbsp;元</div>
			<router-link to="/assets/cashing">去提现</router-link>
		</div>
		<div class="tit">最近交易</div>
		<div class="content">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="交易明细" name="1" />
				<el-tab-pane label="收入记录" name="2" />
				<el-tab-pane label="支出记录" name="3" />
			</el-tabs>
			<el-table :data="newList" current-row-key="id">
				<el-table-column prop="order_sn" label="交易编号" />
				<el-table-column prop="time" label="时间" />
				<el-table-column prop="type" label="用途" />
				<el-table-column prop="money" label="金额" />
				<el-table-column prop="status" label="状态" />
			</el-table>
			<el-pagination layout="prev, pager, next" :total="pagination.total" :hide-on-single-page="pagination.hide"
				:page-size="pagination.pageSize" :current-page="pagination.currentPage" @current-change="currentChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: '1',
				money: '',
				tableData: [
					[],
					[],
					[]
				],
				pagination: {
					hide: true,
					total: 0,
					pageSize: 10,
					currentPage: 1
				},
				index: 0
				
			}
		},
		created() {
			this.$axios('/cash').then(data => {
				// console.log("moeny",data)
				this.money = data
			})
			this.getzichan()
		},
		methods: {
			getzichan(){
				this.$axios('/zichan').then(res => {
					// console.log(res)
					if (res.code == 0 && res.list) {
						res.list.forEach(item => {
							this.tableData[0] = res
							if (item.status == 0) {
								item.status = '审核中'
							} else if (item.status == 1) {
								item.status = '交易成功'
							} else if (item.status == 2) {
								item.status = '交易失败'
							}
							if (item.zc == 0) {
								this.tableData[2].push(item)
							} else if (item.zc == 1) {
								this.tableData[1].push(item)
							}
							this.tableData.splice(0, 1, this.tableData[item.zc])
							// console.log(this.tableData.splice(0, 1, this.tableData[item.zc]))
							this.pagination.total = this.tableData[0].length
						})
					}
				})
			},
			handleClick(tab, event) {
				this.index = tab.index;
				this.pagination.currentPage = 1;
				this.pagination.total = this.tableData[this.index].length;
			},
			currentChange(size) {
				this.pagination.currentPage = size;
			},
		},
		computed: {
			newList() {
				let ind = this.index
				/* slice() 方法可从已有的数组中返回选定的元素。slice 包头不包尾 */
				// console.log(this.tableData[ind])
				return this.tableData[ind].slice((this.pagination.currentPage - 1) * this.pagination.pageSize, this.pagination.currentPage * this.pagination.pageSize)
			}
		}
	}
</script>

<style scoped>
	.el-page-header{
		background-color: #fff;
	}
	.o-wrap {
		background: #f8f8f8;
	}

	.header {
		display: flex;
		background: #fff;
		align-items: flex-end;
		padding: 35px 28px 45px;
	}

	.header div {
		font-size: 18px;
	}

	.header div span {
		font-size: 30px;
		font-weight: bold;
	}

	.header a {
		font-size: 16px;
		color: #426cfd;
		margin-left: 72px;
	}
	.tit {
		font-size: 16px;
		/* margin-top: 13px; */
		line-height: 44px;
		/* background-color: #fff; */
	}

	.content {
		font-size: 14px;
		background-color: #fff;
		padding: 15px;
	}
</style>
